@keyframes spinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

main#home section#welcome {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 400px;
  background-color: rgba(147, 128, 108, 0.1); }
  main#home section#welcome i#settings {
    position: absolute;
    top: -250px;
    left: -250px;
    font-size: 630px;
    pointer-events: none;
    animation: spinner 120s linear infinite;
    color: rgba(147, 128, 108, 0.15); }
    @media all and (max-width: 768px) {
      main#home section#welcome i#settings {
        font-size: 440px;
        top: -180px;
        left: -180px; } }
    @media all and (max-width: 575px) {
      main#home section#welcome i#settings {
        top: -160px;
        left: -160px;
        font-size: 380px; } }
  main#home section#welcome h3 {
    font-size: 24px;
    font-family: 'Noto Serif';
    text-align: center;
    line-height: 1.35;
    margin: 0 20px;
    max-width: 600px;
    color: #111111; }
    @media all and (max-width: 768px) {
      main#home section#welcome h3 {
        font-size: 20px;
        max-width: 90%; } }
    @media all and (max-width: 575px) {
      main#home section#welcome h3 {
        font-size: 16px;
        max-width: 80%; } }
  main#home section#welcome a#contact {
    position: absolute;
    bottom: 30px;
    background-color: transparent;
    border: 1px solid #666666;
    padding: 13px 26px; }
    main#home section#welcome a#contact:hover {
      background-color: white; }

main#home section#practices {
  max-width: 1100px;
  margin: 70px auto 0 auto;
  padding: 0 90px; }
  @media all and (max-width: 575px) {
    main#home section#practices {
      padding: 0 20px; } }
  main#home section#practices div.areas {
    display: flex;
    justify-content: space-between; }
    @media all and (max-width: 768px) {
      main#home section#practices div.areas {
        display: block; } }
    main#home section#practices div.areas > div {
      flex: 1; }
      main#home section#practices div.areas > div:not(:last-child) {
        margin-right: 100px; }
      main#home section#practices div.areas > div h5 {
        font-family: 'Noto Serif';
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
        font-size: 20px;
        margin-bottom: 40px; }
      main#home section#practices div.areas > div p {
        font-size: 18px;
        line-height: 36px;
        text-align: justify; }
      @media all and (max-width: 959px) {
        main#home section#practices div.areas > div:not(:last-child) {
          margin-right: 40px; } }
      @media all and (max-width: 768px) {
        main#home section#practices div.areas > div {
          margin-bottom: 30px; }
          main#home section#practices div.areas > div:not(:last-child) {
            margin-right: 0; } }
  main#home section#practices div.more {
    margin-top: 20px;
    text-align: right; }
    main#home section#practices div.more a {
      text-decoration: underline; }
