main#notfound {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;
  min-height: 500px;
  display: flex;
  justify-content: center; }
  @media all and (max-width: 575px) {
    main#notfound {
      padding: 0 20px; } }
  main#notfound div {
    width: 500px;
    text-align: center; }
    main#notfound div i {
      font-size: 256px;
      color: rgba(147, 128, 108, 0.2); }
    main#notfound div p {
      line-height: 1.8;
      font-size: 16px; }
    main#notfound div a {
      text-decoration: underline; }
