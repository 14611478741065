main#privacy {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;
  min-height: 500px; }
  @media all and (max-width: 575px) {
    main#privacy {
      padding: 0 20px; } }
  main#privacy section#version {
    margin: 15px auto 0 auto;
    text-align: right; }
