main#contact {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;
  min-height: 500px; }
  @media all and (max-width: 575px) {
    main#contact {
      padding: 0 20px; } }
  main#contact section.text div#contact {
    margin: 34px 0; }
    main#contact section.text div#contact h5 {
      font-family: 'Noto Serif';
      font-weight: bold;
      color: rgba(51, 51, 51, 0.85);
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 20px; }
    main#contact section.text div#contact div {
      font-family: 'Noto Serif';
      margin: 0;
      font-size: 18px;
      line-height: 30px; }
  main#contact section.text p.contact {
    font-size: 18px;
    line-height: 32px; }
  main#contact section#map {
    padding: 15px 0 50px 0; }
