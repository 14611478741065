main#practices {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;
  min-height: 500px; }
  @media all and (max-width: 575px) {
    main#practices {
      padding: 0 20px; } }
  main#practices > h3 {
    font-size: 21px;
    font-family: 'Noto Serif';
    text-transform: uppercase;
    margin-bottom: 50px;
    font-weight: bold;
    color: rgba(51, 51, 51, 0.85); }
  main#practices > div {
    display: flex; }
    main#practices > div ul#quicklist {
      flex: 1;
      margin-right: 60px; }
      @media all and (max-width: 575px) {
        main#practices > div ul#quicklist {
          display: none; } }
      main#practices > div ul#quicklist li {
        text-transform: uppercase;
        margin-bottom: 30px;
        line-height: 21px; }
        main#practices > div ul#quicklist li a {
          text-decoration: underline; }
    main#practices > div ul#mainlist {
      flex: 3;
      margin-right: 10%; }
      @media all and (max-width: 959px) {
        main#practices > div ul#mainlist {
          margin-right: 0; } }
      main#practices > div ul#mainlist li {
        margin-bottom: 60px; }
        main#practices > div ul#mainlist li h5 {
          font-family: 'Noto Serif';
          text-transform: uppercase;
          font-size: 21px;
          margin-bottom: 40px;
          font-weight: bold;
          color: rgba(51, 51, 51, 0.85); }
        main#practices > div ul#mainlist li div p {
          font-size: 18px;
          text-align: justify;
          line-height: 36px; }
          main#practices > div ul#mainlist li div p:not(:last-child) {
            margin-bottom: 20px; }
