@charset "UTF-8";
footer#footer {
  margin-top: 100px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: 'Noto Serif'; }
  @media all and (max-width: 575px) {
    footer#footer p {
      display: none; } }
  footer#footer p:after {
    content: '•';
    margin: 0 10px; }
  footer#footer nav ul {
    display: flex; }
    footer#footer nav ul li:not(:last-child):after {
      content: '•';
      margin: 0 10px; }
