@charset "UTF-8";
main#attorneys {
  margin: 0 auto;
  min-height: 500px; }
  main#attorneys section.attorney:first-of-type {
    margin-bottom: 40px; }
  main#attorneys section.attorney > div.lead {
    background-color: rgba(147, 128, 108, 0.1);
    margin-bottom: 60px; }
    main#attorneys section.attorney > div.lead > div.inner {
      display: flex;
      margin: 0 auto;
      margin-bottom: -2px;
      padding: 0 40px;
      max-width: 1250px; }
      @media all and (max-width: 959px) {
        main#attorneys section.attorney > div.lead > div.inner {
          padding: 0 40px; } }
      @media all and (max-width: 768px) {
        main#attorneys section.attorney > div.lead > div.inner {
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0; } }
      main#attorneys section.attorney > div.lead > div.inner > div.contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1; }
        main#attorneys section.attorney > div.lead > div.inner > div.contact h1 {
          font-family: 'Noto Serif';
          color: #111111;
          font-size: 34px;
          margin: 0 0 30px 0; }
          @media all and (max-width: 959px) {
            main#attorneys section.attorney > div.lead > div.inner > div.contact h1 {
              font-size: 24px;
              margin: 30px 0 10px 0; } }
        main#attorneys section.attorney > div.lead > div.inner > div.contact div.details {
          display: flex;
          margin-bottom: 30px; }
          @media all and (max-width: 959px) {
            main#attorneys section.attorney > div.lead > div.inner > div.contact div.details {
              margin-bottom: 20px; } }
          main#attorneys section.attorney > div.lead > div.inner > div.contact div.details p {
            display: flex;
            height: 34px;
            align-items: center;
            font-size: 18px; }
            main#attorneys section.attorney > div.lead > div.inner > div.contact div.details p i {
              margin-bottom: -2px;
              margin-right: 16px;
              color: rgba(0, 0, 0, 0.6); }
            main#attorneys section.attorney > div.lead > div.inner > div.contact div.details p.telephone {
              margin-right: 36px; }
              @media all and (max-width: 959px) {
                main#attorneys section.attorney > div.lead > div.inner > div.contact div.details p.telephone {
                  margin-right: 30px; } }
      main#attorneys section.attorney > div.lead > div.inner > div.image {
        flex: 1;
        width: 50%; }
        @media all and (max-width: 768px) {
          main#attorneys section.attorney > div.lead > div.inner > div.image {
            width: 100%; } }
        main#attorneys section.attorney > div.lead > div.inner > div.image img {
          width: 100%;
          filter: brightness(1.25); }
  main#attorneys section.attorney > section {
    display: flex;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 40px; }
    @media all and (max-width: 959px) {
      main#attorneys section.attorney > section {
        display: block; } }
    @media all and (max-width: 575px) {
      main#attorneys section.attorney > section {
        padding: 0 20px; } }
    main#attorneys section.attorney > section > div.bio {
      flex: 1;
      margin-right: 70px; }
      @media all and (max-width: 959px) {
        main#attorneys section.attorney > section > div.bio {
          margin-right: 0; } }
      main#attorneys section.attorney > section > div.bio p {
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 30px;
        text-align: justify; }
        main#attorneys section.attorney > section > div.bio p:first-of-type {
          margin-top: -10px; }
        main#attorneys section.attorney > section > div.bio p:last-of-type {
          margin-bottom: 40px; }
        main#attorneys section.attorney > section > div.bio p.matter:before {
          content: '·';
          margin-right: 15px; }
        @media all and (max-width: 575px) {
          main#attorneys section.attorney > section > div.bio p {
            line-height: 24px; } }
      main#attorneys section.attorney > section > div.bio h1#matters {
        font-size: 21px;
        font-family: 'Noto Serif';
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 40px; }
    main#attorneys section.attorney > section > div.credentials {
      flex: 1; }
      main#attorneys section.attorney > section > div.credentials > div.info > div {
        padding: 60px 0;
        border-top: 1px solid #CCCCCC;
        font-size: 18px; }
        main#attorneys section.attorney > section > div.credentials > div.info > div h2 {
          font-size: 21px;
          font-family: 'Noto Serif';
          font-weight: bold;
          color: rgba(51, 51, 51, 0.85);
          text-transform: uppercase;
          margin-bottom: 30px; }
        main#attorneys section.attorney > section > div.credentials > div.info > div.education > div {
          line-height: 40px; }
          main#attorneys section.attorney > section > div.credentials > div.info > div.education > div:before {
            margin-left: 4px;
            content: '·';
            margin-right: 18px; }
          main#attorneys section.attorney > section > div.credentials > div.info > div.education > div > div {
            display: inline; }
          main#attorneys section.attorney > section > div.credentials > div.info > div.education > div > div.major,
          main#attorneys section.attorney > section > div.credentials > div.info > div.education > div > div.awards {
            margin-left: 10px; }
            main#attorneys section.attorney > section > div.credentials > div.info > div.education > div > div.major:before,
            main#attorneys section.attorney > section > div.credentials > div.info > div.education > div > div.awards:before {
              content: '·';
              margin-right: 10px; }
        main#attorneys section.attorney > section > div.credentials > div.info > div.practices p, main#attorneys section.attorney > section > div.credentials > div.info > div.admissions p, main#attorneys section.attorney > section > div.credentials > div.info > div.languages p {
          line-height: 40px; }
          main#attorneys section.attorney > section > div.credentials > div.info > div.practices p:before, main#attorneys section.attorney > section > div.credentials > div.info > div.admissions p:before, main#attorneys section.attorney > section > div.credentials > div.info > div.languages p:before {
            margin-left: 4px;
            content: '·';
            margin-right: 18px; }
