header#header {
  width: 100%;
  max-width: 1250px;
  margin: 40px auto 50px auto;
  padding: 0 40px;
  display: flex; }
  @media all and (max-width: 959px) {
    header#header {
      display: block; } }
  @media all and (max-width: 768px) {
    header#header {
      padding: 0 20px; } }
  header#header h1 {
    margin-right: 44px; }
    header#header h1 a {
      display: inline-block; }
      header#header h1 a svg#logo {
        height: 36px; }
    @media all and (max-width: 959px) {
      header#header h1 {
        display: flex;
        justify-content: center;
        margin-bottom: 30px; } }
  header#header nav {
    flex: 1; }
    header#header nav ul {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      white-space: nowrap; }
      @media all and (max-width: 959px) {
        header#header nav ul {
          justify-content: space-between; } }
      header#header nav ul li {
        text-transform: uppercase;
        font-size: 18px; }
        header#header nav ul li:not(:last-child) {
          margin-right: 44px; }
          @media all and (max-width: 959px) {
            header#header nav ul li:not(:last-child) {
              margin-right: 0; } }
        @media all and (max-width: 575px) {
          header#header nav ul li {
            font-size: 12px; } }
        header#header nav ul li a:hover {
          text-decoration: underline; }
        header#header nav ul li a.active {
          pointer-events: none;
          text-decoration: underline; }
